import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { Title, Twitter, Testonimal } from "../../components/Globals";
import { FaAngleDoubleDown } from "react-icons/fa";
const LookerConsultingPage = () => {
    const ref = React.createRef();

    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
  return (
        <Layout>
         
            <div className="looker-consulting-background flex flex-col">
            <Helmet>
        <title>Looker Consulting Services | Looker BI | Cymetrix Software</title>
        <meta name="description" content="Cymetrix Software offers Looker Consulting Services to help businesses explore, analyze, and share data in real time. For data analytics and Looker BI consulting, contact Cymetrix."/>
        <meta name="keywords" content="cymetrix, Looker services, Looker implementation, Looker BI, Looker consulting, Looker consulting services, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/services/looker-consulting/" />
      </Helmet>

            <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
               LOOKER CONSULTING PARTNER TO TRANSFORM YOUR DATA ANALYTICS
                    </h1>
                <button class="focus:outline-none " >
         <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
        </button>
            </div>
            <div className=" mx-0 flex justify-center ">
                <div className="relative max-w-6xl mx-auto  mb-10 sma:mx-3" style={{backgroundColor: '#f6f6f6'}} ref={ref}>
                    <h1 className="sm:text-2xl text-center lg:text-4xl font-bold my-10" style={{color: '#1e407b'}}>LOOKER CONSULTING</h1>
                    <div class="text-gray-700">
                        <h1 className="text-lg  px-5 font-bold uppercase" style={{color: '#1e407b'}}>Looker Consultancy</h1>
                        
                        <p className="mt-4 px-5 text-justify"> We are<Link to="https://www.cymetrixsoft.com/" class="underline text-blue-700">Cymetrix software</Link>, Looker's consulting partner based in USA, we provide looker consultancy services. We empower you to make the best decisions based on your looker data services. We do so by helping you reconstruct your raw data into powerful dashboards, customize widgets, and features on the correct platform so that every individual in your organization is capable of designing, participating, and aid from the observations provided by looker analytics. <br /> <br /></p>
                    </div>
                   
                    <div class="text-gray-700">
                        <h1 className="text-lg px-5 font-bold uppercase" style={{color: '#1e407b'}}>end-to-end looker implementation services</h1>
                        
                        <p className="mt-4 px-5 text-justify">Cymetrix Software - <Link to="/services/salesforce-development-services/" class="underline text-blue-700">Offshore Salesforce Development Company</Link> cover all the aspects right from requirement analysis, business requirements gathering, designing of the model(LookML), data integration, implementation, dashboards, and reports prototype development, UIs, and Looker APIs.  Not only this, we provide detailed end-user training to beef up our relationship.</p>
                    </div>

                    <div class="mt-10 text-gray-700">
                        <h1 className="text-lg  px-5 font-bold uppercase" style={{color: '#1e407b'}}>Experts for a reason</h1>
                        
                        <p className="mt-4 px-5 text-justify">Backed with our industry experience over the years, we ace at implementing robust looker solutions for your business goals. We are trained at Looker architecture and roadmaps so as to make Looker implementation effortless for your organization.</p>
                    </div>
                </div>
            </div>
            

        

            <Testonimal />
            <div className="lg:mt-10 mt-5">
                <Twitter />
            </div>
        </Layout>
    );
}

export default LookerConsultingPage;